import React from 'react';

interface Interactive {
  title: string;
  imageUrl: string;
  description?: string; // optional description
  link?: string; // optional link
}

const interactivesData: Interactive[] = [
  {
    title: 'Economy Map',
    imageUrl: 'http://www.chrisjohnson.net/images/econo_map.gif',
  },
  {
    title: 'Indy 500 Tracker',
    imageUrl: 'http://www.chrisjohnson.net/images/race_09.gif',
  },
  {
    title: 'Colts Statistics',
    imageUrl: 'http://www.chrisjohnson.net/images/colts_stat.gif',
  },
  {
    title: '2008 Primaries site',
    imageUrl: 'http://www.chrisjohnson.net/images/election08.jpg',
  },
  {
    title: 'High School Database',
    imageUrl: 'http://www.chrisjohnson.net/images/indyHSdb.gif',
  },
  {
    title: 'Indy911',
    imageUrl: 'http://www.chrisjohnson.net/images/indy911-2.gif',
  },
  {
    title: 'Manning Database',
    imageUrl: 'http://www.chrisjohnson.net/images/manning_db_thumb.gif',
  },
  {
    title: 'Manning Meter',
    imageUrl: 'http://www.chrisjohnson.net/images/manning_meter2.gif',
  },
  {
    title: 'MapIndy.com',
    imageUrl: 'http://chrisjohnson.net/images/indynav.jpg',
  },
  {
    title: 'Volunteer Database',
    imageUrl: 'http://www.chrisjohnson.net/images/volunteer.gif',
  },
  {
    title: 'Murders Map',
    imageUrl: 'http://www.chrisjohnson.net/images/murders_new.gif',
  },
  {
    title: 'Gordon Gauge',
    imageUrl: 'http://www.chrisjohnson.net/images/gordon.jpg',
  },
  {
    title: 'Interactive Trail map',
    imageUrl: 'http://www.chrisjohnson.net/images/monon_thumb.gif',
  },
  {
    title: 'Panther Racing',
    imageUrl: 'http://www.chrisjohnson.net/images/panther_07.gif',
  },
  {
    title: 'History of 500',
    imageUrl: 'http://www.chrisjohnson.net/images/history_500.gif',
  },
  {
    title: '2007 Indy 500 Results',
    imageUrl: 'http://www.chrisjohnson.net/images/500_post_2007.gif',
  },
  {
    title: 'Colts Season Center',
    imageUrl: 'http://www.chrisjohnson.net/images/colts_center.gif',
  },
  {
    title: 'Live Election Results',
    imageUrl: 'http://www.chrisjohnson.net/images/electionlive.gif',
  },
  {
    title: 'Heartland Film Festival',
    imageUrl: 'http://www.chrisjohnson.net/images/heartlandfilmfestival.gif',
  },
  {
    title: 'F1 Results',
    imageUrl: 'http://www.chrisjohnson.net/images/f1_thumb.gif',
  },
  {
    title: 'Brickyard Results',
    imageUrl: 'http://www.chrisjohnson.net/images/by_thumb.gif',
  },
  {
    title: 'State Fair timeline',
    imageUrl: 'http://www.chrisjohnson.net/images/fair_thumb.gif',
  },
  {
    title: 'Indy 500 Tech',
    imageUrl: 'http://www.chrisjohnson.net/images/500tech.gif',
  },
  {
    title: 'Anatomy of a Team',
    imageUrl: 'http://www.chrisjohnson.net/images/500team.gif',
  },
  {
    title: 'Indy 500',
    imageUrl: 'http://www.chrisjohnson.net/images/500results.gif',
  },
  {
    title: 'Marion County Vacancies',
    imageUrl: 'http://www.chrisjohnson.net/images/vacancy.gif',
  },
  {
    title: 'Daily Danica',
    imageUrl: 'http://www.chrisjohnson.net/images/500danica.gif',
  },
  {
    title: 'IRL/IMS Crash DB',
    imageUrl: 'http://www.chrisjohnson.net/images/crash_db.gif',
  },
  {
    title: 'Coke/Coal Plant Emissions',
    imageUrl: 'http://www.chrisjohnson.net/images/cokeplant.gif',
  },
  {
    title: 'Downtown Hot Spots',
    imageUrl: 'http://www.chrisjohnson.net/images/downtown.gif',
  },
  {
    title: 'Children’s BMI calculator',
    imageUrl: 'http://www.chrisjohnson.net/images/bmi.gif',
  },
  {
    title: 'Hurricane Tracker',
    imageUrl: 'http://www.chrisjohnson.net/images/HurrTracker_SM.jpg',
  },
  {
    title: 'Marino Gear',
    imageUrl: 'http://www.chrisjohnson.net/images/Marino_SM.jpg',
  },
  {
    title: 'Driving the Hunley',
    imageUrl: 'http://www.chrisjohnson.net/images/Hunley_SM.jpg',
  },
  {
    title: 'Sabers Hockey Game',
    imageUrl: 'http://www.chrisjohnson.net/images/Hockey_SM.jpg',
  },
  {
    title: 'Jimmy Nuetron: Carl Squared',
    imageUrl: 'http://www.chrisjohnson.net/images/carl_squared.gif',
    link: 'https://jimmyneutron.fandom.com/wiki/Carl_Squared_(online_game)',
  },
  {
    title: 'Jimmy Nuetron: SeaMinus',
    imageUrl: 'http://www.chrisjohnson.net/images/sea_minus.gif',
    link: 'https://jimmyneutron.fandom.com/wiki/Sea_Minus_(online_game)',
  },
  {
    title: 'Jimmy Nuetron: Hypercorn',
    imageUrl: 'http://www.chrisjohnson.net/images/hyper_corn.gif',
    link: 'https://jimmyneutron.fandom.com/wiki/Hypercorn_(online_game)',
  },
  {
    title: 'Jimmy Nuetron: UltraLord',
    imageUrl: 'http://www.chrisjohnson.net/images/ultra_lord.gif',
    link: 'https://jimmyneutron.fandom.com/wiki/Ultralord_vs._the_Squirrels_(online_game)',
  }
];

const InteractiveItem: React.FC<Interactive> = ({ title, imageUrl, description, link }) => {
  return (
    <div key={title} className="bg-white rounded-lg border shadow-md sm:max-w-xs md:max-w-none overflow-hidden max-w-none p-4">
      <img className="h-30 lg:h-30 w-full object-cover mb-5 border mb-2" src={imageUrl} alt={title} />
      <div className="">
        <h3 className="font-semibold text-xl leading-6 text-gray-900 my-2">
          {title}
        </h3>
        {description && <p className="paragraph-normal text-gray-600">{description}</p>}
        {link && <a className="mt-3 block text-blue-500" href={link}>Read More </a>}
      </div>
    </div>
  );
};

const InteractiveWork: React.FC = () => {
  const items = ['item1', 'item2', 'item3', 'item4', 'item5'];
  return (
    <section className="max-w-6xl mx-auto">

      <h1 className="text-3xl font-bold mb-6">Interactive Data</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6">
        {interactivesData.map((interactive) => (
          <InteractiveItem key={interactive.title} {...interactive} />
        ))}
        
      </div>
    </section>
  );
};

export default InteractiveWork;