import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Resume from './pages/Resume';
import JobHistory from './pages/JobHistory';
import AIColabs from './pages/AIColabs';
import InteractiveWork from './pages/InteractiveWork';
import About from './pages/About';
import Chatbot from './components/Chatbot';
import DataVizWork from './pages/DataVisualization';
import CensusMap from './pages/projects/CensusMap';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="resume" element={<Resume />} />
          <Route path="job-history" element={<JobHistory />} />
          <Route path="ai" element={<AIColabs />} />
          <Route path="interactive-work" element={<InteractiveWork />} />
          <Route path="about" element={<About />} />
          <Route path="data-viz" element={<DataVizWork />} />
          <Route path="projects/census-map" element={<CensusMap />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
